import React from 'react'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Software from '../../Assets/Software.svg';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";

const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "unset",
    borderRadius: "unset",
    background: "none",
}));
const Index = () => {
    const isMobile = useMediaQuery("(max-width:899px)");
    const isLaptop = useMediaQuery("(max-width:1440px)");
    const isSmLaptop = useMediaQuery("(max-width:1240px)");

    return (
        <Item sx={{ padding:isMobile?"42px 16px 55px 16px":isSmLaptop?"32px 24px":"42px 36px 55px 43px", display:isMobile?"flex":"", flexDirection:isMobile?"column":"", alignItems:isMobile?"center":"" }}>
            <img
                src={Software}
                alt="Software"
                style={{
                    width: "115px",
                    height: "83px",
                }}
            />
            <Grid sx={{ mt: "42px" }} >
                <Typography variant="h1" className='MontserratFont h2-text' style={{ color: "#000000", textAlign:isMobile?"center":"" }}>What’s the Cost to <br /> Develop Your Custom <br /> Software<span style={{ color: "#3DCED9" }} className='MontserratFont h2-text'>?</span></Typography>

            </Grid>
            <Grid sx={{ mt: "22px" }} >
                <Typography variant="body1" className='MontserratFont para-text' style={{ color: "#7B7B7B", textAlign:isMobile?"center":"" }}>To give you an accurate estimate, we <br style={{display:isLaptop?"none":""}}/> need a little more info about your <br style={{display:isLaptop?"none":""}}/> software project. Answer a few quick <br style={{display:isLaptop?"none":""}}/> questions, and we’ll tailor a cost <br style={{display:isLaptop?"none":""}}/> estimate just for you.</Typography>

            </Grid>
            <Grid sx={{ mt:isMobile?"20px":"117px" }} >
                <Typography variant="body1" className='DmFont para-text-Dm' style={{ color: "#434A65", textAlign:isMobile?"center":"" }}>Support</Typography>
                <Typography variant="body1" className='DmFont para-text-Dm_color' style={{ color: "#3DCED9", cursor: "pointer", textAlign:isMobile?"center":"" }}>support@w3speedup.com</Typography>
            </Grid>
        </Item>
    )
}

export default Index
