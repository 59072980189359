import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import LeftComponent from "../LeftSideComponent/Index";
import RightComponent from "./RightSideComponent/Index";

const Index = () => {
	return (
		<Grid
			sx={{
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Container
				maxWidth={"lg"}
				style={{
					padding: "0px",
					boxShadow: "0px 4px 90.5px 0px #3DCED91A",
					background: "white",
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={0}>
						<Grid item xs={4} sx={{ borderRight: "2px solid #00000040" }}>
							<LeftComponent />
						</Grid>
						<Grid item xs={8} className="fadIN" sx={{ position: "relative" }}>
							<RightComponent />
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Grid>
	);
};

export default Index;
