import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
const customTheme = (outerTheme) =>
	createTheme({
		palette: {
			mode: outerTheme.palette.mode,
		},
		components: {
			MuiTextField: {
				styleOverrides: {
					root: {
						"--TextField-brandBorderColor": "#D7E1F3",
						"--TextField-brandBorderHoverColor": "#D7E1F3",
						"--TextField-brandBorderFocusedColor": "#D7E1F3",
						"& label.Mui-focused": {
							color: "var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						borderColor: "var(--TextField-brandBorderColor)",
						borderRadius: "4px",
					},
					root: {
						"& #filled-basic-email": {
							fontWeight: 500,
							fontSize: "14px",
							lineHeight: "22px",
							color: "#858A93",
							padding: "12px 19px",
							background: "#FAFCFF",
						},
						[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
							border: "2px solid var(--TextField-brandBorderHoverColor)",
						},
						[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: "var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						"&::before, &::after": {
							borderBottom: "2px solid var(--TextField-brandBorderColor)",
						},
						"&:hover:not(.Mui-disabled, .Mui-error):before": {
							borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
						},
						"&.Mui-focused:after": {
							borderBottom:
								"2px solid var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
			MuiInput: {
				styleOverrides: {
					root: {
						"&::before": {
							borderBottom: "2px solid var(--TextField-brandBorderColor)",
						},
						"&:hover:not(.Mui-disabled, .Mui-error):before": {
							borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
						},
						"&.Mui-focused:after": {
							borderBottom:
								"2px solid var(--TextField-brandBorderFocusedColor)",
						},
					},
				},
			},
		},
	});
const Item = styled(Paper)(({ theme }) => ({
	boxShadow: "unset",
	borderRadius: "unset",
	background: "none",
}));
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Index = () => {
	const outerTheme = useTheme();

	return (
		<Item sx={{ padding: "44px 45px" }}>
			<Typography
				variant="h3"
				className="MontserratFont"
				style={{ color: "#3DCED9", fontSize: "14px", fontWeight: 600, lineHeight: "17px" }}
			>
				Almost done!
			</Typography>
			<Typography
				variant="h3"
				className="MontserratFont h3-text"
				style={{ color: "#000000", marginTop: "22px" }}
			>
				Please provide your contact details
			</Typography>

			<Box sx={{ flexGrow: 1, mt: "32px" }}>
				<Grid container spacing={2} sx={{gap:"10px"}}>
					<Grid size={6}>
						<Item>
							<Typography className="MontserratFont para-text" style={{ color: "#4F555A", marginBottom: "8px" }}>You Name</Typography>
							<ThemeProvider theme={customTheme(outerTheme)}>
								<TextField
									className="LableStyleTextFeild"
									InputProps={{
										style: {
										},
									}}
									sx={{
										width: "100%",
									}}
									id="filled-basic-email"
									variant="outlined"
									type="email"
									name="email"
								/>
							</ThemeProvider>
						</Item>
					</Grid>
					<Grid size={6}>
						<Item>
							<Typography className="MontserratFont para-text" style={{ color: "#4F555A", marginBottom: "8px" }}>Email Address</Typography>
							<ThemeProvider theme={customTheme(outerTheme)}>
								<TextField
									className="LableStyleTextFeild"
									InputProps={{
										style: {
										},
									}}
									sx={{
										width: "100%",
									}}
									id="filled-basic-email"
									variant="outlined"
									type="email"
									name="email"
								/>
							</ThemeProvider>
						</Item>
					</Grid>
					<Grid size={6}>
						<Item>
							<Typography className="MontserratFont para-text" style={{ color: "#4F555A", marginBottom: "8px" }}>Your Phone</Typography>
							<ThemeProvider theme={customTheme(outerTheme)}>
								<TextField
									className="LableStyleTextFeild"
									InputProps={{
										style: {
											// borderLeft: "8px solid var(--border-color-Green)",
										},
									}}
									sx={{
										width: "100%",
									}}
									id="filled-basic-email"
									variant="outlined"
									// label="Work email*"
									type="email"
									name="email"
								/>
							</ThemeProvider>
						</Item>
					</Grid>
					<Grid size={6}>
						<Item>
							<Typography className="MontserratFont para-text" style={{ color: "#4F555A", marginBottom: "8px" }}>Company Name (optional)</Typography>
							<ThemeProvider theme={customTheme(outerTheme)}>
								<TextField
									className="LableStyleTextFeild"
									InputProps={{
										style: {
											// borderLeft: "8px solid var(--border-color-Green)",
										},
									}}
									sx={{
										width: "100%",
									}}
									id="filled-basic-email"
									variant="outlined"
									// label="Work email*"
									type="email"
									name="email"
								/>
							</ThemeProvider>
						</Item>
					</Grid>
					<Grid size={12}>
						<Item>
							<Typography className="MontserratFont para-text" style={{ color: "#4F555A", marginBottom: "8px" }}>Project Description (optional)</Typography>
							<ThemeProvider theme={customTheme(outerTheme)}>
								<TextField
									className="LableStyleTextFeild DescriptionTextFeild"
									InputProps={{
										style: {
										},
									}}
									sx={{
										width: "100%",
									}}
									id="filled-basic-email"
									variant="outlined"
									type="email"
									name="email"
								/>
							</ThemeProvider>
						</Item>
					</Grid>
				</Grid>
			</Box>

			<Grid sx={{ mt: "18px", display: "flex", alignItems: "center", gap: "12px" }}>
				<Checkbox style={{
					color: "#3DCED9",
					fontSize: "55px",
					padding: "0px",
				}} {...label} defaultChecked />
				<Typography className="MontserratFont para-text" style={{ color: "#4F555A" }}>I accept the <span className="para-text" style={{ color: "#3DCED9", cursor: "pointer" }}>Privacy Policy</span> and <span className="para-text" style={{ color: "#3DCED9", cursor: "pointer" }}>Terms and Conditions.</span></Typography>
			</Grid>


			<Grid
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "11px",
					position: "absolute",
					bottom: "45px",
					right: "44px",
				}}
			>
				<Link to='/maintenance'>
					<Button variant="outlined" className="PreBtn">
						Previous
					</Button>
				</Link>
				<Button variant="contained" className="NextBtn">
					Submit
				</Button>
			</Grid>
		</Item>
	);
};

export default Index;
