import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Industry from './Components/Industry/Index';
import Contact from './Components/Contact/Index';
function App() {
  return (
    <div>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Industry/>}>
        </Route>
        <Route path="/contact" element={<Contact/>}>
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
